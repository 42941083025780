<template>
  <div id="a" style="z-index: -1">
  </div>
  <Toast/>
</template>
<script>
import E from "wangeditor"

export default {
  data() {
    return {
      editor: null,
      content:this.value
    }
  },
  emits:["input"],
  model: {
    prop: 'value',
    event: 'input'
  },
  props: ['value'],
  watch:{
    value(val){
      this.content = val
      this.editor.txt.html(val)
    }
  },

  mounted() {
    let ths = this;
    this.editor = new E("#a")
    this.editor.config.uploadImgServer = this.$global_msg.frontendUrl + '/file/uploadImg'
    this.editor.config.uploadFileName = 'files';
    this.editor.config.zIndex = 500
    this.editor.config.customAlert = function (info) {
      // info 是需要提示的内容
      //alert('自定义提示：' + info)
      ths.$toast.add({severity: 'info', summary: '提示：', detail: info, life: 3000});
    }
    this.editor.config.onchange = (html) => {
      ths.$emit('input', html)
    }
    this.editor.create()
    this.editor.txt.html(this.content)
  },
  unmounted() {
    this.editor.destroy()
    this.editor = null
  }
};
</script>